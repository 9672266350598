import {Chip} from "@material-ui/core";
import React from "react";


const CLASS_MAP = {
    "A": "allergen-icon-celery",
    "B": "allergen-icon-sesame",
    "C": "allergen-icon-peanut",
    "D": "allergen-icon-nuts",
    "E": "allergen-icon-lupin",
    "F": "allergen-icon-soyabean",
    "G": "allergen-icon-cereal",
    "H": "allergen-icon-egg",
    "I": "allergen-icon-fish",
    "J": "allergen-icon-mollusc",
    "L": "allergen-icon-milk",
    "M": "allergen-icon-mustard",
    "N": "allergen-icon-sulfide",
    "K": "allergen-icon-shrimp"
};

export const AllergenIcon = (
    {
        allergen,
        color = "#333",
        size = 16,
        display = 'inline',
        float = 'left',
        padding = ".25em .5em",
        marginLeft  = ".25em",
        showName= true
    }
) => {

    const styles = {
        fontSize: `${size}px`,
        color: `${color}`,
        display : display,
        marginLeft: marginLeft,
        float: float,
        padding : padding
    }, classes = `${CLASS_MAP[allergen.identifier]}`;


    const labelStyle = {
        textTransform : 'uppercase',
        fontSize : (size * 0.7) + 'px',
        position: "relative",
        top: "-.25em",
        marginLeft : ".1em"
    };

    const Label = () => <span style={labelStyle}>{allergen.name}</span>;
    const Icon =  () => <i className={classes} style={{color : color}} title={allergen.name} />;

    return !showName
        ? <div style={styles} className={classes}  title={allergen.name}/>
        : <div style={styles}><Icon /><Label /></div>
};


export const AllergensBlock = ({allergens}) => {

    if (!allergens || !allergens.length) {
        return <p className={"wrap allergen"}>
            <b>Sin alérgenos declarados.</b>
        </p>
    }

    return <p className={"wrap allergen"}>
        {allergens.map(
            (a, loop) => <Chip label={a.name} color={"red"}/>
        )}
    </p>
};


export const AllergensIconsBlock = ({allergens}) => {

    if (!allergens || !allergens.length) {
        return <p className={"wrap"}>
            <b>Sin alérgenos declarados.</b>
        </p>
    }

    return allergens.map(
            (a, loop) => <AllergenIcon
                            allergen={a}
                            color={"white"}
                            size={30}
                            showName={false}
                            padding={0}
            />
        )

};
