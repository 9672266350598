import React, {useState} from "react";
import {TabPanel} from "./TabPanel";
import {Tabs, Paper, CardContent} from '@material-ui/core'

const blockStyle={
    maxWidth : '100%',
    overflowY : 'auto'
};

export const MuiTabs = (
    {   tabs,
        panels,
        indicatorColor="primary",
        orientation="horizontal",
        tabClasses=""
    }) => {

    const [value, setValue] = useState(0);

    const handleChange = (e, value) => {
        setValue(value)
    };

    return <CardContent>
        <Paper>
            <Tabs value={value}
                  onChange={handleChange}
                  indicatorColor={indicatorColor}
                  orientation={orientation}
                  className={tabClasses}
                  variant="scrollable"
                  allowScrollButtonsMobile
                  scrollButtons={"auto"}
            >
                {tabs}
            </Tabs>
        </Paper>
        <Paper style={blockStyle}>
            {
                panels.map(
                    (p, loop) => <TabPanel
                        style={{paddingRight: 0, paddingLeft: 0}}
                        value={value}
                        index={loop}>
                        {p}
                    </TabPanel>)}
        </Paper>
    </CardContent>
};
