import React, {useState} from 'react';
import ReactMapboxGl, {Feature, Layer, Popup} from 'react-mapbox-gl/lib/index';
import mapboxgl from 'mapbox-gl';
import {Button, Card, CardContent, Divider, Grid, LinearProgress} from '@material-ui/core';
import {Close} from '@material-ui/icons';
import {Link} from "react-router-dom/index";


// The following is required to stop "npm build" from transpiling mapbox code.
// notice the exclamation point in the import.
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved


const Map = ReactMapboxGl({
    accessToken: 'pk.eyJ1IjoiZW1nb3lhIiwiYSI6ImNsMDJjdzV4eDA0dHgzY3AzM3ExbDVtYTkifQ.fAyzbI8ikgqg8VQIoWzKMQ'
});

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const POSITION_CIRCLE_PAINT = {
    'circle-stroke-width': 4,
    'circle-radius': 12,
    'circle-blur': 0.15,
    'circle-color': '#3F51B5',
    'circle-stroke-color': '#AAAAAA'
};

const getCoords = (item) => {
    return [item.longitude, item.latitude];
};


const Pop = ({item, onClose, config}) => {

    if (!item) return null;

    const {uxLogo} = item;


    const imageUrl = uxLogo
        ? `${config.url_prefix}/uploads/media/${uxLogo.context}/0001/01/${uxLogo.providerReference}`
        : null
    ;

    const link = item.webLink ? item.webLink :
        `https://www.google.es/maps/place/${item.showName}/@${item.latitude},${item.longitude},15z`
    ;
    

    return <Popup coordinates={getCoords(item)}>
        <CardContent>
            <Grid container style={{width: "360px"}} spacing={3}>
                <Grid item xs={12}>
                    <Close className={"close-icon"} onClick={onClose}/>
                    {imageUrl && <img className={"logo"} src={imageUrl} alt={item.showName}/>}
                </Grid>
                <Divider/>
                <Grid item xs={12}>
                    <Grid container spacing={3} style={{"text-align": "center"}}>
                        <Grid item xs={12}>
                            <h5 className={"customer-name"}>{item.showName}</h5>
                            <div><b>{item.ownerAddress}</b></div>
                            <div>{item.ownerPhone}</div>
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant={"contained"} color={"primary"} className={"site-link"}>
                                <a
                                    className={"color-white"}
                                    href={link}
                                    key={item.ownerSlug}
                                    target={"_blank"}
                                >
                                    Más información
                                </a>
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </CardContent>
    </Popup>
};


const processData = (d) => {

    return {
        'name': d.ownerName,
        'latitude': parseFloat(d.ownerLatitude),
        'longitude': parseFloat(d.ownerLongitude),
        ...d
    }


};

export const CustomerMap = ({width = "100vw", APISERVICE, customer}) => {

    const d = processData(customer);

    const coordinates = getCoords(d);
    const [center, setCenter] = useState(coordinates);
    const [marked, setMarked] = useState(null);

    const closeTip = () => {
        setMarked(null);
    };


    return <>
        {(coordinates && coordinates[0] && coordinates[1]) ? <div className="page">
            <div id="map" className={"full"}>
                <Map

                    style="mapbox://styles/mapbox/streets-v11"
                    center={center}
                    zoom={[10]}
                    containerStyle={{
                        height: "80vh",
                        width: width,
                    }}
                >
                    <Layer
                        type="circle"
                        id="marker"
                        paint={POSITION_CIRCLE_PAINT}
                    >
                        {[d].map((m, loop) =>
                            <Feature key={m.id}
                                     coordinates={getCoords(m)}
                                     onClick={() => {
                                         setMarked(loop);
                                         setCenter(getCoords(m))
                                     }}
                            />)}
                    </Layer>
                    <Pop config={APISERVICE.config} item={[d][marked]} onClose={() => {
                        closeTip()
                    }}/>
                </Map>
            </div>
        </div> : <LinearProgress/>}
    </>
};




