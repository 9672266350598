import React from "react";

export const  TabPanel = (props) => {
    const {children, value, index, ...other} = props;

    return (
        <div
            style={{"width": "100%", 'padding' : '1em'}}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && children}
        </div>
    );
};
